import React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useClickOutside } from '@alvogen/shared//hooks/useClickOutside';

import { useUI } from 'utils/ui';
import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { DropNavigation } from 'components/drop-navigation/DropNavigation';
import { Dropdown } from 'components/drop-navigation/Dropdown';

type Props = {
  navigationMain: Array<{
    title: string;
    link: ILink;
    image: IImage;
    items: Array<{
      title: string;
      link: ILink;
      nested: boolean;
      showInNavigation: boolean;
    }>;
  }>;
  navigationFooter: Array<{
    text: string;
    link: ILink;
  }>;
  languages?: Array<{
    label: string;
    link: ILink;
  }>;
};

export const DropNavigationContainer = ({ navigationMain, navigationFooter, languages }: Props) => {
  const router = useRouter();
  const ui = useUI();
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [activeParent, setActiveParent] = React.useState(null);
  const navigationRef = React.useRef();
  const [timerId, setTimerId] = React.useState(null);

  useClickOutside(navigationRef, () => setActiveIndex(-1));

  const onScroll = () => {
    ui.toggleTopNavigation(false);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const isSectionActive = (router, slug) => {
    if (!router.query.parentId) {
      const split = router.asPath.split('/').filter((x) => x !== '');
      const first = split[0];
      const locale = router.locale;

      if (`/${locale}/${first}` === slug) {
        return `/${locale}/${first}` === slug;
      }

      return `/${first}` === slug;
    }

    return router.query.parentId === slug;
  };

  const isItemActive = (router, uid) => {
    if (`/${router.locale}${router.asPath}` === uid) {
      return `/${router.locale}${router.asPath}` === uid;
    }

    return router.asPath === uid;
  };

  return (
    <DropNavigation
      ref={navigationRef}
      isOpen={ui.isTopNavigationOpen}
      // menuIndex={activeIndex}
      // onToggleSearch={onSearchClick}
      languages={languages}
      topNav={navigationFooter?.map((item) => (
        <Link
          key={item.text}
          // onClick={() => ui.toggleTopNavigation(false)}
          // {...item.link}
          href={item.link?.href}
          uid={item.link?.uid}
          // isActive={isItemActive(router, item.link.href)}
        >
          {item.text}
        </Link>
      ))}
      mainNav={<Dropdown nav={navigationMain} />}
    ></DropNavigation>
  );
};
