import React from 'react';
import classnames from 'classnames';
import { TweenLite } from 'gsap';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { IconSearch2 } from 'components/assets';
import { Languages } from './Languages';
import { ILink } from 'utils/types';

import s from './DropNavigation.module.scss';

type Props = {
  topNav?: React.ReactNode;
  mainNav?: React.ReactNode;
  children?: React.ReactNode;
  isOpen?: boolean;
  menuIndex?: number;
  languages?: Array<{
    label: string;
    link: ILink;
  }>;
  onToggleSearch?: (e: any) => void;
};

export const DropNavigation = React.forwardRef<HTMLElement, Props>(
  ({ topNav, mainNav, children, isOpen, menuIndex, languages, onToggleSearch }: Props, ref) => {
    const { i18n } = useLingui();

    const toggleSearch = (e) => {
      onToggleSearch(e);
    };

    return (
      <nav ref={ref} className={classnames(s.nav, { [s.isOpen]: isOpen })}>
        <div className={s.nav__nav}>
          <div className={s.nav__top}>
            {topNav}

            <Languages languages={languages} />

            {process.env.SEARCH_ENABLED === 'true' && (
              <button
                className={s.nav__toggle}
                onClick={toggleSearch}
                aria-label={i18n._(t`Toggle Search`)}
              >
                <IconSearch2 />
              </button>
            )}
          </div>
          <div className={s.nav__main}>{mainNav}</div>
        </div>
      </nav>
    );
  }
);
