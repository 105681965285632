import React from 'react';
import { useRouter } from 'next/router';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import classnames from 'classnames';

import { localeUrlResolver } from 'utils/localeUrlResolver';
import { IconSearch2 } from 'components/assets';

import s from './SearchBox.module.scss';

type Props = {
  focus?: boolean;
  iconColor?: 'white' | 'brand';
  onSearched?: (e?: any) => void;
};

export const SearchBox = ({ focus, iconColor = 'brand', onSearched = () => {} }: Props) => {
  const router = useRouter();
  const { i18n } = useLingui();
  const inputRef = React.useRef<HTMLInputElement>();

  const actionUrl = localeUrlResolver(router, '/search');

  const handleSubmit = (event) => {
    event.preventDefault();
    router.push({
      pathname: actionUrl,
      query: { q: inputRef.current.value },
    });
    onSearched();
  };

  React.useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    } else {
      inputRef.current.value = '';
    }
  }, [focus]);

  return (
    <div className={classnames(s.search, s[iconColor])}>
      <form method="get" action={actionUrl} onSubmit={handleSubmit}>
        <button className={s.search__button} aria-label={i18n._(t`Search`)}>
          <IconSearch2 />
        </button>
        <input
          className={s.search__input}
          name="q"
          ref={inputRef}
          type="search"
          placeholder={i18n._(t`Search`)}
          aria-label={i18n._(t`Search`)}
        />
      </form>
    </div>
  );
};
