import React from 'react';
import { Elements, RichText as PrismicRichText, RichTextBlock } from 'prismic-reactjs';
import Image from 'next/image';
import { linkResolver } from 'utils/resolver';
import { Link } from 'components/link/Link';

type Props = {
  render?: RichTextBlock[];
};

const htmlSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.image: {
      return (
        <div key={key} className="block-img">
          <Image
            src={element.url}
            width={element.dimensions.width}
            height={element.dimensions.height}
            alt={element.alt || ''}
          />
        </div>
      );
    }
    case Elements.hyperlink: {
      if (element.data?.link_type === 'Media' && element.data?.url.includes('.mp3')) {
        return (
          <audio controls preload="auto" key={key} style={{ width: '100%' }}>
            <source src={element.data.url} type="audio/mpeg" />
            <a href={element.data.url}>{element.data.name}</a>
          </audio>
        );
      }

      return (
        <Link
          key={key}
          href={element.data?.link_type === 'Web' ? element.data?.url : element.data?.slug}
          target={element.data?.target}
        >
          {content}
        </Link>
      );
    }
    case Elements.oListItem: {
      return (
        <li key={key}>
          <span>{children}</span>
        </li>
      );
    }
    default:
      return null;
  }
};

export const RichText = ({ render = [] }: Props) => RichText.render(render);

RichText.asText = (text: RichTextBlock[] = [], emptyIsNull = false) => {
  const str = PrismicRichText.asText(text || [])?.trim();
  return str === '' && emptyIsNull ? null : str.trim();
};

RichText.render = (text: RichTextBlock[] = []) => {
  if (RichText.asText(text) === '') {
    return null;
  }

  return (
    <PrismicRichText
      render={text || []}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializer}
    />
  );
};
