import React from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { ChevronRight } from 'components/assets';

import s from './Button.module.scss';

type Props = {
  color?: 'brand' | 'white' | 'dark' | undefined;
  hover?: 'brand' | 'white' | 'dark' | undefined;
  staticColor?: boolean;
  small?: boolean;
  link?: ILink;
  onClick?: (event?: React.MouseEvent) => void;
  children: React.ReactNode;
  [rest: string]: any;
  noIcon?: boolean;
};

export const Button = ({
  color = 'brand',
  small = false,
  hover,
  link,
  staticColor,
  noIcon = false,
  children,
  ...rest
}: Props) => {
  const className = classnames(s.button, {
    [s.brand]: color === 'brand',
    [s.white]: color === 'white',
    [s.dark]: color === 'dark',
    [s.hoverBrand]: hover === 'brand',
    [s.hoverWhite]: hover === 'white',
    [s.hoverDark]: hover === 'dark',
    [s.small]: small,
    [s.static]: staticColor,
    [s.noIcon]: noIcon,
  });

  // add target _blank to media links
  if (link && !link.target) {
    link.target = link.name ? '_blank' : undefined;
  }

  const content = (
    <span className={s.button__content}>
      <span className={s.button__text}>{children}</span>
      {!small && !noIcon && (
        <span className={s.button__icon}>
          <ChevronRight />
        </span>
      )}
    </span>
  );

  if (link) {
    return (
      <Link className={className} {...link} {...rest}>
        {content}
      </Link>
    );
  }

  return (
    <button className={className} {...rest}>
      {content}
    </button>
  );
};
