import React from 'react';
import { config } from 'utils/config';

import Close from './close.svg';
import Alvogen from './alvogen.svg';
import Alvotech from './alvotech.svg';
import Lotus from './lotus.svg';
import Adalvo from './adalvo.svg';
import Almatica from './almatica.svg';
import Almaject from './almaject.svg';
import ArrowUp from './arrow-up.svg';
import ChevronRight from './chevron-right.svg';
import IconPlus from './icon-plus.svg';
import SocialIconFacebook from './social-icon-facebook.svg';
import SocialIconTwitter from './social-icon-twitter.svg';
import SocialIconLinkedin from './social-icon-linkedin.svg';
import SocialIconCrunchbase from './cb.svg';
import IconDownload from './icon-square-download.svg';
import IconSearch from './icon-search.svg';
import IconSearch2 from './icon-search-2.svg';
import IconMail from './icon-mail.svg';
import IconVolume from './icon-volume.svg';
import IconMute from './icon-mute.svg';
import IconText from './icon-text.svg';
import IconPlay from './icon-play.svg';
import IconCaption from './icon-caption.svg';
import IconPlayAlt from './icon-play-alt.svg';
import IconPause from './icon-pause.svg';
import IconGlobe from './icon-globe.svg';

import AlvotechBasket from './user-icons/Alvotech_basket.svg';
import AlvotechBiosimilar from './user-icons/Alvotech_biosimilar2.svg';
import AlvotechCosmetics from './user-icons/Alvotech_cosmetics.svg';
import AlvotechEmploeeys from './user-icons/Alvotech_emploeeys.svg';
import AlvotechGenericProjects from './user-icons/Alvotech_generic-projects.svg';
import AlvotechGreen from './user-icons/Alvotech_green.svg';
import AlvotechHandPill from './user-icons/Alvotech_hand-pill.svg';
import AlvotechKeyGrowth from './user-icons/Alvotech_key-growth copy.svg';
import AlvotechManufacturinCenters from './user-icons/Alvotech_manufacturin-centers.svg';
import AlvotechMap from './user-icons/Alvotech_map.svg';
import AlvotechMedical from './user-icons/Alvotech_medical.svg';
import AlvotechMicroscope from './user-icons/Alvotech_microscope.svg';
import AlvotechMolecule from './user-icons/Alvotech_molecule.svg';
import AlvotechNetwork from './user-icons/Alvotech_network.svg';
import AlvotechPipeline from './user-icons/Alvotech_pipeline2.svg';
import AlvotechPlayground from './user-icons/Alvotech_playground.svg';
import AlvotechAward from './user-icons/Alvotech_award.svg';
import AlvotechBiosGenerics from './user-icons/Alvotech_bios-generics.svg';
import AlvotechCapacity from './user-icons/Alvotech_capacity.svg';
import AlvotechCompany from './user-icons/Alvotech_company.svg';
import AlvotechDna from './user-icons/Alvotech_dna.svg';
import AlvotechInvestment from './user-icons/Alvotech_investment.svg';
import AlvotechManufacturing from './user-icons/Alvotech_manufacturing.svg';
import AlvotechPeople from './user-icons/Alvotech_people.svg';
import AlvotechPills from './user-icons/Alvotech_pills.svg';
import AlvotechProfessional from './user-icons/Alvotech_professional.svg';
import AlvotechSquareMeter from './user-icons/Alvotech_square-meter.svg';

import EsgPine from './esg/Pine.svg';
import EsgBulb from './esg/Bulb.svg';
import EsgDrop from './esg/Drop.svg';
import EsgBin from './esg/Bin.svg';
import EsgPeople from './esg/People.svg';
import EsgHeart from './esg/Heart.svg';
import EsgRibbon from './esg/Ribbon.svg';
import EsgExclamation from './esg/Exclamation.svg';
import EsgLock from './esg/Lock.svg';
import EsgMicroscope from './esg/Microscope.svg';
import EsgList from './esg/List.svg';

let BrandLogo = Alvogen;
switch (config.theme) {
  case 'alvotech':
    BrandLogo = Alvotech;
    break;
  case 'lotus':
    BrandLogo = Lotus;
    break;
  case 'adalvo':
    BrandLogo = Adalvo;
    break;
  case 'almatica':
    BrandLogo = Almatica;
    break;
  case 'almaject':
    BrandLogo = Almaject;
    break;
  default:
    BrandLogo = Alvogen;
    break;
}

export {
  Close,
  BrandLogo,
  Alvogen,
  Alvotech,
  Lotus,
  ArrowUp,
  ChevronRight,
  IconPlus,
  SocialIconFacebook,
  SocialIconTwitter,
  SocialIconLinkedin,
  SocialIconCrunchbase,
  IconDownload,
  IconSearch,
  IconSearch2,
  IconMail,
  IconVolume,
  IconMute,
  IconText,
  IconPlay,
  IconPlayAlt,
  IconPause,
  IconGlobe,
  IconCaption,
  AlvotechBasket,
  AlvotechBiosimilar,
  AlvotechCosmetics,
  AlvotechEmploeeys,
  AlvotechGenericProjects,
  AlvotechGreen,
  AlvotechHandPill,
  AlvotechKeyGrowth,
  AlvotechManufacturinCenters,
  AlvotechMap,
  AlvotechMedical,
  AlvotechMicroscope,
  AlvotechMolecule,
  AlvotechNetwork,
  AlvotechPipeline,
  AlvotechPlayground,
  AlvotechAward,
  AlvotechBiosGenerics,
  AlvotechCapacity,
  AlvotechCompany,
  AlvotechDna,
  AlvotechInvestment,
  AlvotechManufacturing,
  AlvotechPeople,
  AlvotechPills,
  AlvotechProfessional,
  AlvotechSquareMeter,
  EsgPine,
  EsgBulb,
  EsgDrop,
  EsgBin,
  EsgPeople,
  EsgHeart,
  EsgRibbon,
  EsgExclamation,
  EsgLock,
  EsgMicroscope,
  EsgList,
};
