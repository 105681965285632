import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';

import s from './DropNavigation.module.scss';

type Props = {
  nav?: any;
  link?: ILink;
  label?: string;
  children?: React.ReactNode;
  isOpen?: boolean;
  isActive?: boolean;
};

type ItemProps = {
  link: ILink;
  isActive?: boolean;
  children?: React.ReactNode;
};

export const Dropdown = ({ nav, link, label, children, isOpen, isActive }: Props) => {
  const router = useRouter();
  const [activeParent, setActiveParent] = React.useState(null);

  const isSectionActive = (router, slug) => {
    if (!router.query.parentId) {
      const split = router.asPath.split('/').filter((x) => x !== '');
      const first = split[0];
      const locale = router.locale;

      if (`/${locale}/${first}` === slug) {
        return `/${locale}/${first}` === slug;
      }

      return `/${first}` === slug;
    }

    return router.query.parentId === slug;
  };

  const isItemActive = (router, uid) => {
    if (`/${router.locale}${router.asPath}` === uid) {
      return `/${router.locale}${router.asPath}` === uid;
    }

    return router.asPath === uid;
  };

  const transition = {
    duration: 0.25,
    ease: 'easeInOut',
  };

  return (
    <NavigationMenu.Root className={s.navigation} delayDuration={0} skipDelayDuration={500}>
      <NavigationMenu.List className={s.navigation__list}>
        {nav
          ?.filter((item) => !!item.link)
          .map((nav, index) => {
            if (nav.items.length > 0) {
              const isActive = index === activeParent;

              return (
                <NavigationMenu.Item key={index} className={s.navigation__item}>
                  <NavigationMenu.Trigger
                    className={classnames(s.navigation__trigger, { [s.isActive]: isActive })}
                  >
                    {nav.title}
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className={s.navigation__content}>
                    <motion.div layoutId="dropdown" layout="position" transition={transition}>
                      <motion.div
                        className={s.navigation__wrap}
                        layout="position"
                        layoutId="sublist"
                        transition={transition}
                      >
                        <motion.div
                          className={s.navigation__indicator}
                          layoutId="arrow"
                          transition={transition}
                        >
                          <motion.div className={s.navigation__arrow} />
                        </motion.div>
                        <motion.div
                          className={s.navigation__inner}
                          layout
                          layoutId="inner"
                          transition={transition}
                        >
                          <motion.ul className={s.navigation__subList}>
                            {nav.items.map((item, i) => {
                              const active = isItemActive(router, item.link.href);
                              if (active && activeParent !== index) {
                                setActiveParent(index);
                              }

                              return (
                                <motion.div key={index} layout>
                                  <Item link={item.link} isActive={active}>
                                    {item.title}
                                  </Item>
                                </motion.div>
                              );
                            })}
                          </motion.ul>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              );
            } else {
              return (
                <NavigationMenu.Link key={index}>
                  <motion.div layout>
                    <Item link={nav.link} isActive={isSectionActive(router, nav.link.href)}>
                      {nav.title}
                    </Item>
                  </motion.div>
                </NavigationMenu.Link>
              );
            }
          })}
        {/* <NavigationMenu.Indicator className={s.navigation__indicator}>
          <div className={s.navigation__arrow} />
        </NavigationMenu.Indicator> */}
      </NavigationMenu.List>
      {/* <NavigationMenu.Viewport className={s.navigation__viewport} /> */}
    </NavigationMenu.Root>
  );
};

const Item = ({ link, isActive, children }: ItemProps) => {
  return (
    <Link
      className={classnames(s.item, { [s.isActive]: isActive })}
      {...link}
      href={link?.href}
      uid={link?.uid}
      isActive={isActive}
    >
      {children}
    </Link>
  );
};
